import { getOfflineCouponSetup, getOfflineCoupon, deleteOfflineCouponDetails, updateOfflineCouponOnline, updateOfflineCouponOffline, updateOfflineIdRank } from '@/services/coupon'
import jsonData from './jsonData.js'
import allButton from '@/components/allButton'
import searchForm from '@/components/searchForm'
import createTable from '@/components/createTable'
import dialogEffect from '@/components/dialogEffect'
import { mixin } from '@/mixins/mixin'
import loading from '@/utils/loading.json'
export default {
  name: 'OfflineCoupon',
  mixins: [mixin],
  components: {
    searchForm,
    createTable,
    allButton,
    dialogEffect
  },
  data () {
    return {
      tableDataItem: jsonData.tableDataItem
    }
  },
  watch: {
    $route (to, from) {
      // console.log(from)
      // if (from.name === 'managerUserOrderView') {
      //   this.$nextTick(() => {
      //     this.search()
      //     this.$refs.createTable.$refs.table.doLayout()
      //   })
      // }
    }
  },
  created () {
    this.queryData(getOfflineCouponSetup)
  },
  activated () {
    if (this.isActive) this.queryData(getOfflineCouponSetup)
    this.isActive = true
  },
  methods: {
    // 点击操作按钮
    async handleClick (type, data) {
      if (type === 'create') {
        this.$router.push({
          name: 'offlineCouponAdd'
        })
      }
      if (type?.action === 'view') {
        this.$router.push({
          name: 'offlineCouponDetail',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'create') {
        this.$router.push({
          name: 'offlineCouponAdd'
        })
      }
      if (type?.action === 'edit') {
        this.$router.push({
          name: 'offlineCouponEdit',
          params: {
            id: data.id.value
          }
        })
      }
      if (type?.action === 'top') {
        this.$confirm(type.confirmation, this.$t('text.tips'), {
          confirmButtonText: this.$t('text.confirmation'),
          cancelButtonText: this.$t('button.cancel'),
          type: 'warning'
        }).then(() => {
          this.loading = true
          updateOfflineIdRank(data.id.value).then(() => {
            this.loading = false
            this.search()
          }).catch(() => {
            this.loading = false
            this.search()
          })
        }).catch(() => {
        })
      }
      if (type?.action === 'destroy') {
        this.deleteClick(data, deleteOfflineCouponDetails)
      }
    },
    handleSwitchChange (data) {
      const { jsonItemData, rowData } = data
      this.$store.state.app.loading = this.$loading(loading)
      if (rowData[jsonItemData.prop].value) {
        updateOfflineCouponOnline(rowData.id.value).then(res => {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
        }).finally(() => {
          this.$store.state.app.loading.close()
        })
      } else {
        updateOfflineCouponOffline(rowData.id.value).then(res => {
          this.$message({
            type: 'success',
            message: this.$t('message.operateSuccess')
          })
        }).finally(() => {
          this.$store.state.app.loading.close()
        })
      }
    },
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      getOfflineCoupon(this.searchData).then(res => {
        if (res.data) {
          this.tableData = res.data.objects
          this.tabData = res.data.scopes
          Object.assign(this.pageData, res.data.pagination)
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
